import React from "react";
import Styles from "./Styles.module.css";
import { LocalGroceryStore } from "@mui/icons-material";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider6 = () => {
  return (
    <div className={Styles.slider8}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img src="/aboutus/storefront.png" alt="ShamiriVeg" />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Inventory Management</h1>
              <p>
              The ShamiriVeg Inventory Management feature empowers small-scale grocery traders with tools to efficiently track and manage their stock. It offers real-time inventory updates, ensuring that traders are always aware of current stock levels. 
              </p>
              <p>The system integrates with point-of-sale systems to automatically update inventory after every sale, minimizing the risk of stockouts or overstocking. Traders can set low-stock alerts, manage product categories, and track supplier details for easy restocking. </p>
              <p>The app also supports barcode scanning, making stock management faster and more accurate. Additionally, it provides insightful inventory reports, helping traders optimize their stock levels and improve overall business performance.</p>
              <div className={Styles.listing}>
                <h3>App Features</h3>
                {data.map((item, index) => (
                  <ul key={index}>
                    <li>
                      <LocalGroceryStore
                        style={{
                          paddingRight: "2rem",
                          color: "var(--blendGreenColor)",
                        }}
                      />
                      <span style={{ fontWeight: "bold" }}>{item.feature} </span>:
                      - {"\t\t "} {item.item}
                    </li>
                  </ul>
                ))}
              </div>{" "}
            </Zoom>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Slider6;

const data = [
  {
    feature: "Inventory Tracking ",
    item: "Real-Time Stock Monitoring, Batch Tracking,Expiration Date Tracking,Low Stock Alerts:",
  },
  {
    feature: "Product Management ",
    item: "Easy product listing with options to add images, descriptions, prices, and categories. Inventory management to track stock levels and receive notifications for low stock. ",
  },
  {
    feature: "Automated Reordering",
    item: "Reorder Point Calculation, Supplier Integration, Order Generation",
  },
  {
    feature: "Payment Integration",
    item: "Secure payment gateways integrated into the app, supporting various payment methods Including: MPESA mobile money, credit/debit cards, and bank transfers.",
  },
  {
    feature: " Product Categorization",
    item: "Customizable Categories: Organize inventory into customizable categories (e.g., fruits, vegetables, grains). Multi-Level Categorization, Tagging ",
  },
  {
    feature: "Inventory Forecasting",
    item: "Demand Prediction, Sales Forecasting, Lead Time Analysis.",
  },
  {
    feature: "Order Management",
    item: "Order Processing, Order Tracking, Backorder Management",
  },
  {
    feature: "Inventory Reporting and Analytics",
    item: "Inventory Reports, Turnover Rate Analysis, Profitability Analysis, Stock Value Reports",
  },
  {
    feature: "Supplier Management",
    item: "Supplier Database, Supplier Performance Tracking, Purchase Order Management ",
  },
  {
    feature: "Environmental Sustainability Tracking",
    item: "Waste Reduction Tracking, Sustainable Sourcing, Carbon Footprint Analysis  ",
  },
];
