import React from "react";
import Styles from "./Styles.module.css";
import { LocalGroceryStore } from "@mui/icons-material";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider3 = () => {
  return (
    <div className={Styles.slider8}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img src="/aboutus/storefront.png" alt="ShamiriVeg" />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Market Analytics for Traders</h1>
              <p>
              ShamiriVeg’s Market Analytics feature offers small-scale grocery traders valuable insights to help them make data-driven decisions. The feature tracks and analyzes sales trends, customer behavior, and market demand, providing traders with actionable insights to boost profitability. 
              </p>
              <p>
              It includes real-time reporting on best-selling products, peak sales periods, and customer preferences. By utilizing this data, traders can optimize their inventory, improve pricing strategies, and target high-demand products. 
              </p>
              <p>The analytics tool also includes competitor analysis and market trend forecasts, helping traders stay ahead of market shifts. Overall, it enables traders to make informed decisions, increasing efficiency and business growth</p>
              <div className={Styles.listing}>
                <h3>App Features</h3>
                {data.map((item, index) => (
                  <ul key={index}>
                    <li>
                      <LocalGroceryStore
                        style={{
                          paddingRight: "2rem",
                          color: "var(--blendGreenColor)",
                        }}
                      />
                      <span style={{ fontWeight: "bold" }}>
                        {item.feature}{" "}
                      </span>
                      : - {"\t\t "} {item.item}
                    </li>
                  </ul>
                ))}
              </div>{" "}
            </Zoom>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Slider3;

const data = [
  {
    feature: "Demand Forecasting ",
    item: "Predict which products will be in demand based on historical data, market trends, and seasonality.",
  },
  {
    feature: "Price Monitoring ",
    item: "Track current market prices for different products to help farmers decide the best time to sell. ",
  },
  {
    feature: "Supply Chain Analytics",
    item: "Analyze supply chain efficiency, identify bottlenecks, and suggest improvements.",
  },
  {
    feature: "Weather and Climate Analytics",
    item: "Offer insights on weather patterns and their potential impact on crop yield and market demand.",
  },
  {
    feature: " Customer Feedback Analytics",
    item: "Analyze customer reviews and feedback to understand preferences and areas for improvement.",
  },
  {
    feature: "Yield Prediction Models",
    item: "Use data to predict potential yield, helping farmers to make informed decisions about planting and harvesting.",
  },

  {
    feature: "Sales Trends and Patterns",
    item: "Monitor sales trends over time to identify peak seasons, best-selling products, and customer preferences.",
  },
  {
    feature: "Feedback and Review Trends:",
    item: "Summarize feedback and reviews, offering transparency on product quality and vendor reliability.",
  },
];
