import React, { useState } from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";

const Slider10 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Handle form submission logic here
    console.log("Form submitted:", formData);

    // Reset form fields
    setFormData({
      name: "",
      phone: "",
      email: "",
      message: "",
    });

    // Show the success message
    setSubmitted(true);

    // Optionally, hide the message after a few seconds
    setTimeout(() => {
      setSubmitted(false);
    }, 3000); // Hide after 3 seconds
  };

  const isFormDataFilled =
    formData.name !== "" &&
    formData.email !== "" &&
    formData.phone !== "" &&
    formData.message !== "";

  const buttonStyle = {
    backgroundColor: isFormDataFilled ? "var(--stargreen)" : "grey",
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: isFormDataFilled ? "pointer" : "not-allowed",
  };

  return (
    <div className={Styles.slider10}>
      <div className={Styles.mainContainer}>
        <div>
          <form onSubmit={handleSubmit} className={Styles.container}>
            <div className={Styles.productCard}>
              <Zoom>
                <h3>Get More information about ShamiriVeg Products</h3>
                <h4>Tel: +254 738 900 254 </h4>
                <h4>Email: info@shamiriveg.com</h4>
                <div className={Styles.slider9InputHolder}>
                  <input
                    placeholder="*Name"
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <input
                    placeholder="*Phone No"
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
              </Zoom>
            </div>
            <div className={Styles.productCard}>
              <div className={Styles.slider9leftholder}>
                <p>
                  For more detailed information about ShamiriVeg, Tools and Platform, please please get intouch!{" "}
                </p>
                <div className={Styles.slider9InputHolder}>
                  <input
                    placeholder="*Email"
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <textarea
                    placeholder="*Nature of Enquiry"
                    rows="1"
                    type="text"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <button
                    type="submit"
                    disabled={!isFormDataFilled}
                    style={buttonStyle}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {submitted && (
          <div className={Styles.submittedForm}>
            Thanks, Successfully Submitted
          </div>
        )}
      </div>
    </div>
  );
};

export default Slider10;
