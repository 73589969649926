import React from "react";
import { Routes, Route } from "react-router-dom";
import Nomatch from "./nomatch";
import Home from "./home";
import Layout from "./components/layout/Layout";
import Layout2 from "./components/layout/Layout2";
import AboutUs from "./components/generalpages/aboutus";
import ContactUs from "./components/generalpages/contactus";
import Donate from "./components/generalpages/donate";
import Careers from "./components/generalpages/careers";
import FAQs from "./components/generalpages/faqs";
import PrivacyPolicy from "./components/generalpages/privacypolicy";
import SiteMap from "./components/generalpages/sitemap";
import TermsOfService from "./components/generalpages/termsofservice";
import StoreFront from "./components/products/storefront";
import DeliveryApp from "./components/products/deliveryapp";
import InventoryManagement from "./components/products/inventorymanagement";
import CustomerEngagementTools from "./components/products/customerengagementtools";
import FinanceBackOffice from "./components/products/financebackoffice";
import MarketAnalytics from "./components/products/marketanalytics";

function AppRouter() {
  return (
    <>
      <Routes>
        <>
          {/* Layout1 */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/Contact Us" element={<ContactUs />}></Route>
            <Route path="/Careers" element={<Careers />}></Route>
            <Route
              path="/Frequently Asked Questions"
              element={<FAQs />}
            ></Route>
            <Route path="/Privacy Policy" element={<PrivacyPolicy />}></Route>
          </Route>{" "}
        </>
        <>
          {/* Layout2 */}
          <Route path="/" element={<Layout2 />}>
            <Route path="/About Us" element={<AboutUs />} />
            <Route path="/Donate" element={<Donate />}></Route>
            <Route path="/Storefront Shop" element={<StoreFront />}></Route>
            <Route path="/Delivery App" element={<DeliveryApp />}></Route>
            <Route
              path="/Inventory Management"
              element={<InventoryManagement />}
            ></Route>
            <Route
              path="/Customer Engagement Tools"
              element={<CustomerEngagementTools />}
            ></Route>
            <Route
              path="/Finance Backend for Traders"
              element={<FinanceBackOffice />}
            ></Route>
            <Route
              path="/Market Analytics for Traders"
              element={<MarketAnalytics />}
            ></Route>
            <Route path="/Site Map" element={<SiteMap />}></Route>
            <Route
              path="/Terms Of Service"
              element={<TermsOfService />}
            ></Route>
            <Route path="*" element={<Nomatch />} />
          </Route>
        </>
        <>
          {/* Layout3
          <Route path="/" element={<Layout3 />}></Route> */}
        </>
      </Routes>
    </>
  );
}

export default AppRouter;
