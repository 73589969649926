import React from "react";
import Styles from "./Styles.module.css";
import Slider8 from "./sliders/Slider8";
import SkeletonDisplay from "../hooks/skeletonDisplay";

function StoreFront() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img src="/aboutus/aboutuspage.png" alt="ShamiriVeg" />
          </SkeletonDisplay>
          <button>Store Front</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <Slider8 />
      </div>
    </div>
  );
}

export default StoreFront;
