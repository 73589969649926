import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Storefront from "../assets/Storefront.jpg";
import DeliveryApp from "../assets/DeliveryApp.jpg";
import Inventory from "../assets/Inventory.jpg";
import Engagement from "../assets/Engagement.jpg";
import FinanceApp from "../assets/FinanceApp.jpg";
import MarketAnalytics from "../assets/MarketAnalytics.jpg";

const MyCarousel = () => {
  const items = [
    {
      title: "Storefront Shop (E-commerce)",
      description:
        "Storefronts for customers, driving sales and expanding their reach in the digital marketplace",
      imageUrl: Storefront,
      link: "/Storefront Shop",
    },
    {
      title: "Delivery App",
      description:
        "Integrated with the Storefront to efficiently manage and process customer orders for doorstep delivery",
      imageUrl: DeliveryApp,
      link: "/Delivery App",
    },
    {
      title: "Inventory Management",
      description:
        "Inventory tracking, stock alerts, and product categorization, optimize stock levels, minimize wastage, and meet customer demand",
      imageUrl: Inventory,
      link: "/Inventory Management",
    },
    {
      title: "Customer Engagement Tools",
      description:
        "Provides a suite of customer engagement tools, including reviews, chats, complaints, and feedback functionalities.",
      imageUrl: Engagement,
      link: "/Customer Engagement Tools",
    },
    {
      title: "Finance Backend for Traders",
      description:
        "Tracking sales revenue and expenses to generating financial reports and forecasts, make informed decisions and manage finances effectively",
      imageUrl: FinanceApp,
      link: "/Finance Backend for Traders",
    },
    {
      title: "Market Analytics for Traders",
      description:
        "Equips traders with valuable insights and data-driven intelligence to make informed business decisions",
      imageUrl: MarketAnalytics,
      link: "/Market Analytics for Traders",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel responsive={responsive}>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            padding: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            textAlign: "center",
          }}
        >
          <Link to={item.link}>
            <img
              src={item.imageUrl}
              alt={item.title}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                marginBottom: "10px",
              }}
            />
          </Link>
        </div>
      ))}
    </Carousel>
  );
};

export default MyCarousel;
