import React from "react";
import Styles from "./Styles.module.css";
import { LocalGroceryStore } from "@mui/icons-material";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider5 = () => {
  return (
    <div className={Styles.slider8}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img src="/aboutus/storefront.png" alt="ShamiriVeg" />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Customer Engagement Tools</h1>
              <p>
                To effectively engage with smallholder farmers and small-scale
                traders, Shamiriveg could incorporate the following features
                into its customer engagement tools
              </p>
              <div className={Styles.listing}>
                <h3>App Features</h3>
                {data.map((item, index) => (
                  <ul key={index}>
                    <li>
                      <LocalGroceryStore
                        style={{
                          paddingRight: "2rem",
                          color: "var(--blendGreenColor)",
                        }}
                      />
                      <span style={{ fontWeight: "bold" }}>
                        {item.feature}{" "}
                      </span>
                      : - {"\t\t "} {item.item}
                    </li>
                  </ul>
                ))}
              </div>{" "}
            </Zoom>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Slider5;

const data = [
  {
    feature: "SMS and WhatsApp Notifications:  ",
    item: "Sending personalized updates, reminders, and tips directly to farmers and traders via SMS or WhatsApp. Providing regular updates on market trends, new features, and success stories, tailored to the interests of different user segments",
  },
  {
    feature: "24X7 Customer Support ",
    item: "Providing customers with instant answers to common questions, assist with onboarding, and guide users through platform features. ",
  },
  {
    feature: "Social Media Integration",
    item: "Group forums and online spaces where farmers and traders can connect, share experiences, and content. Integrated with Facebook, WhatsApp Instagram and X (Formely Twitter)",
  },
  {
    feature: "Training and Educational Resources",
    item: "Video Tutorials, Webinars and Live Q & A Sessions on topics related agriculture best practices, technology adoption, and business management.",
  },
  {
    feature: " Feedback and Survey Tools",
    item: "Regular Surveys: Collect feedback from users to understand their needs, challenges, and satisfaction levels",
  },
  
];
