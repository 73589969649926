import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";
import { Agriculture } from "@mui/icons-material";
import MyCarousel from "../../hooks/MyCarousel";

const Slider3 = () => {
  return (
    <div className={Styles.slider3}>
      <div>
        <h3>ShamiriVeg Digital Market Place Tools for AgriBusiness</h3>
        <MyCarousel />
      </div>
      <div className={Styles.mainContainer}>
        <div className={Styles.listing}>
          <h3>Our Products</h3>
          {data.map((item, index) => (
            <Link to={item.link} key={index}>
              <li>
                <Agriculture
                  style={{
                    paddingRight: "2rem",
                    color: "var(--blendGreenColor)",
                  }}
                />
                <span style={{ fontWeight: "bold" }}>{item.feature} </span>: -{" "}
                {"\t\t "} {item.item}
              </li>
            </Link>
          ))}
        </div>{" "}
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img src="/aboutus/mobileapp.png" alt="ShamiriVeg" />
            </SkeletonDisplay>

            <button className={Styles.infobtn}>Talk to us Today</button>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Linking Small Scale Grocery Traders to Customers</h1>
              <p>
                At ShamiriVeg, we redefine the management and growth of
                small-scale grocery businesses. Our suite of AI-driven tools and
                market analytics enhances productivity, streamlines operations,
                and increases sales, enabling traders to scale their businesses
                efficiently. Through innovative digital marketing strategies, we
                seamlessly connect traders with their customers, accessible from
                their mobile devices. Committed to reshaping the future of
                grocery shopping in Kenya, we empower traders in the
                agribusiness sector with digital tools for success and
                prosperity.
              </p>
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider3;

const data = [
  {
    feature: "Storefront Shop (E-commerce) ",
    item: "Storefronts for customers, driving sales and expanding their reach in the digital marketplace",
    link: "/Storefront Shop",
  },
  {
    feature: "Delivery App ",
    item: ":  Intergrated with the Storefront Shops to efficiently manage and process customer orders for doorstep delivery ",
    link: "/Delivery App",
  },
  {
    feature: "Inventory Management",
    item: "Inventory tracking, stock alerts, and product categorization, optimize stock levels, minimize wastage, and  meet customer demand",
    link: "/Inventory Management",
  },
  {
    feature: "Customer Engagement Tools",
    item: "Provides a suite of customer engagement tools, including reviews, chats, complaints, and feedback functionalities.",
    link: "/Customer Engagement Tools",
  },
  {
    feature: "Finance Backend for Traders",
    item: "Tracking sales revenue and expenses to generating financial reports and forecasts, make informed decisions and manage finances effectively",
    link: "/Finance Backend for Traders",
  },
  {
    feature: "Market Analytics for Traders",
    item: "Equips traders with valuable insights and data-driven intelligence to make informed business decisions",
    link: "/Market Analytics for Traders",
  },
];
