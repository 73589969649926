import React from "react";

import Header from "./components/header/header";
import Slider3 from "./components/generalpages/aboutussliders/Slider3"

function Home() {
  return (
    <div>
      <Header />
      <Slider3/>
    </div>
  );
}

export default Home;
