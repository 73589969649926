import React from "react";
import Styles from "./Styles.module.css";
import { LocalGroceryStore } from "@mui/icons-material";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider7 = () => {
  return (
    <div className={Styles.slider8}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img src="/aboutus/storefront.png" alt="ShamiriVeg" />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Delivery App</h1>
              <p>
                These comprehensive features are designed to ensure that the
                ShamiriVeg Delivery App is not only functional but also
                user-friendly, secure, and aligned with modern e-commerce
                standards.
              </p>
              <div className={Styles.listing}>
                <h3>App Features</h3>
                {data.map((item, index) => (
                  <ul key={index}>
                    <li>
                      <LocalGroceryStore
                        style={{
                          paddingRight: "2rem",
                          color: "var(--blendGreenColor)",
                        }}
                      />
                      <span style={{ fontWeight: "bold" }}>
                        {item.feature}{" "}
                      </span>
                      : - {"\t\t "} {item.item}
                    </li>
                  </ul>
                ))}
              </div>{" "}
            </Zoom>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Slider7;

const data = [
  {
    feature: "Place Order ",
    item: "Browse Products: Explore categories, featured items, and search for specific products. Add to Cart: Favorites/Wishlist: Save items to purchase later or mark as favorites.",
  },
  {
    feature: "Request Delivery Agents ",
    item: "Automatic Assignment Delivery Agents , Option to select a preferred delivery agent based on past experiences and Display the expected time of arrival ",
  },
  {
    feature: "Dispatch Order",
    item: "Real-Time Notifications on when order is dispatched from the store. Real-time updates on the status of your order, including packaging, dispatch, and delivery progress.",
  },
  {
    feature: "Track Order",
    item: "Real-time GPS tracking of your delivery on a map, showing the current location of the delivery agents.",
  },
  {
    feature: " Check Out Order",
    item: "Payment Options, choose from various payment methods (MPESA,  credit/debit cards, cash on delivery).",
  },
  {
    feature: "Feedback and Review",
    item: "Rating Orders, Reviewing Delivery Personnel, Product Reviews and Service Feedback",
  },
  {
    feature: "Order Reports",
    item: "Order History, Downloadable Reports, Spending Analysis and Reorder Function",
  },
  {
    feature: "Loyalty and Rewards",
    item: "Loyalty Program: Earn points or rewards with each purchase that can be redeemed for discounts or free items. Referral Program: Invite friends and earn rewards.",
  },
  {
    feature: "Security Features",
    item: "Secure Payments, Two-Factor Authentication. Data Privacy: Manage data sharing preferences",
  },
];
