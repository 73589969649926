// NavBarData.js
const navLinks = [
  { title: "Home", path: "/" },
  {
    title: "About us",
    path: "/About Us",
  },
  {
    title: "Products",
    path: "/",
    subLinks: [
      "Storefront Shop",
      "Delivery App",
      "Inventory Management",
      "Customer Engagement Tools",
      "Finance Backend for Traders",
      "Market Analytics for Traders",
    ],
  },
  {
    title: "News & Views",
    path: "News & Views",
    subLinks: ["In the News", "Blog", "Press Release", "Publications"],
  },
  {
    title: "Get Involved",
    path: "/Get Involved",
    subLinks: ["Donate", "Volunteers", "Careers"],
  },
  { title: "Contact us", path: "/Contact Us" },
];

export default navLinks;
