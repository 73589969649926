import React, { useState } from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { Facebook, Instagram, LinkedIn, WhatsApp } from "@mui/icons-material";
import {} from "react-icons/fa";

const Slider8 = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
      in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div
      className={Styles.slider8}
      style={{ backgroundColor: "var(--stargreen)!important" }}
    >
      <div className={Styles.mainContainer}>
        <div className={Styles.container8}>
          <div className={Styles.productCard}>
            <Zoom>
              <div className={Styles.footerHighlight}>
                <h3>
                 We provide farmers and small scale traders with Digital Apps for Agribusiness
                </h3>
                <Link to="/">
                  <img src="/aboutus/footerlogo.png" alt="ShamiriVeg" />
                </Link>
              </div>
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <h4>Quick Links</h4>
            {navLinks.map((menulink, index) => (
              <li key={index}>
                <Link
                  style={{
                    paddingTopTop: "-2rem",
                    display: visible ? "inline" : "none",
                  }}
                  onClick={scrollToTop}
                  to={menulink.path}
                >
                  {" "}
                  * {menulink.title}
                </Link>{" "}
              </li>
            ))}
          </div>
          <div className={Styles.productCard}>
            <h4>Key Links</h4>
            {generalLinks.map((menulink, index) => (
              <li key={index}>
                <Link
                  style={{
                    paddingTopTop: "-2rem",
                    display: visible ? "inline" : "none",
                  }}
                  onClick={scrollToTop}
                  to={menulink.path}
                >
                  {" "}
                  * {menulink.title}
                </Link>{" "}
              </li>
            ))}
          </div>
        </div>

        <div className={Styles.footer}>
          <p>
            Copyright : &copy; {new Date().getFullYear()} ShamiriVeg Limited. All Rights Reserved
          </p>
          <div className={Styles.socials}>
            <span>
              <Facebook />
            </span>
            <span>
              <WhatsApp />
            </span>
            <span>
              <Instagram />
            </span>
            <span>
              <LinkedIn />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider8;

const navLinks = [
  { title: "Home", path: "/" },
  {
    title: "Ecommerce Store Front ",
    path: "/Storefront Shop (E-commerce) ",
  },
  {
    title: "Delivery App",
    path: "/Delivery App",
  },
  {
    title: "Inventory Management",
    path: "/Inventory Management",
  },
  {
    title: "Customer Engagement Tools",
    path: "/Customer Engagement Tools",
  },
  {
    title: "Finance Backend for Traders",
    path: "/Finance Backend for Traders",
  },
  {
    title: "Market Analytics for Traders",
    path: "/Market Analytics for Traders",
  },
];

const generalLinks = [
  { title: "Careers", path: "/Careers" },
  { title: "Sitemap", path: "/Site Map" },
  { title: "Frequently Asked Questions", path: "/Frequently Asked Questions" },
  { title: "Home", path: "/" },
  { title: "Privacy Policy", path: "/Privacy Policy" },
  { title: "Terms of Service", path: "/Terms Of Service" },
  {
    title: "Donate: ",
    path: "/Donate",
  },
  { title: "Contact Us", path: "/Contact Us" },
];
