import React from "react";
import Styles from "./Styles.module.css";
import Slider from "../homeslider/Slider"

import SkeletonDisplay from "../hooks/skeletonDisplay";

function Header() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            {/* <img src="/aboutus/aboutus.png" alt="ShamiriVeg" /> */}
           <Slider/>
          
          </SkeletonDisplay>
          <button>Small-Scale Grocery Traders </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
