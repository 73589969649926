const SliderData = [
  {
    id: 1,
    image: "/aboutus/aboutus.png",
    alt: "ShamiriVeg 1",
  },
  {
    id: 2,
    image: "/aboutus/aboutus1.png",
    alt: "ShamiriVeg 2",
  },
  {
    id: 3,
    image: "/aboutus/aboutus3.png",
    alt: "ShamiriVeg 3",
  },

];

export default SliderData;
