import React from "react";
import Styles from "./Styles.module.css";
import {  LocalGroceryStore } from "@mui/icons-material";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider8 = () => {
  return (
    <div className={Styles.slider8}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
        <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/storefront.png"
                alt="ShamiriVeg"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Store Front</h1>
              <p>
                The ShamiriVeg Storefront App offers a range of functionalities
                designed to help traders create and manage their online presence
                effectively. Here are some key functionalities:
              </p>
              <div className={Styles.listing}>
                <h3>App Features</h3>
                {data.map((item, index) => (
                  <li>
                    <LocalGroceryStore
                      style={{
                        paddingRight: "2rem",
                        color: "var(--blendGreenColor)",
                      }}
                    />
                    <span style={{ fontWeight: "bold" }}>{item.feature} </span>:
                    - {"\t\t "} {item.item}
                  </li>
                ))}
              </div>{" "}
            </Zoom>
          </div>{" "}
          
        </div>
      </div>
    </div>
  );
};

export default Slider8;

const data = [
  {
    feature: "Customizable Storefront Templates ",
    item: "Traders can choose from various pre-designed templates to create a storefront that reflects their brand identity.",
  },
  {
    feature: "Product Management ",
    item: "Easy product listing with options to add images, descriptions, prices, and categories. Inventory management to track stock levels and receive notifications for low stock. ",
  },
  {
    feature: "Order Management",
    item: "Real-time order tracking and management. Automated order confirmation and customer notification via email or SMS. Integration with delivery services for seamless shipping and handling.",
  },
  {
    feature: "Payment Integration",
    item: "Secure payment gateways integrated into the app, supporting various payment methods Including: MPESA mobile money, credit/debit cards, and bank transfers.",
  },
  {
    feature: " Customer Engagement Tools",
    item: "Built-in messaging system for direct communication with customers. Loyalty programs and discount coupon management to attract and retain customers. Customer feedback and review system to build trust and improve service.",
  },
  {
    feature: "Analytics and Reporting",
    item: "Sales analytics to track performance, including top-selling products, revenue trends, and customer demographics. Inventory analytics to optimize stock levels and reduce wastage. Customizable reports for financial tracking, tax preparation, and business planning.",
  },
];
