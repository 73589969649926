import React from "react";
import Styles from "./Styles.module.css";
import { LocalGroceryStore } from "@mui/icons-material";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider4 = () => {
  return (
    <div className={Styles.slider8}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img src="/aboutus/storefront.png" alt="ShamiriVeg" />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Finance Backend for Traders</h1>
              <p>
              The ShamiriVeg Financial App is designed to simplify and enhance financial management for small-scale grocery traders. It offers a range of features to help users efficiently track and manage their finances. These include real-time expense tracking, income management, and detailed financial reporting to monitor profitability. 

              </p>
              <p>The app integrates with point-of-sale systems to streamline sales data and automatically update inventory levels. Users can set budgets, monitor cash flow, and track credit extended to customers. Additionally, the app provides insights through customized analytics and alerts, enabling traders to make informed financial decisions and grow their businesses with greater financial control.</p>
              <div className={Styles.listing}>
                <h3>App Features</h3>
                {data.map((item, index) => (
                  <ul key={index}>
                    <li>
                      <LocalGroceryStore
                        style={{
                          paddingRight: "2rem",
                          color: "var(--blendGreenColor)",
                        }}
                      />
                      <span style={{ fontWeight: "bold" }}>{item.feature} </span>:
                      - {"\t\t "} {item.item}
                    </li>
                  </ul>
                ))}
              </div>{" "}
            </Zoom>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Slider4;

const data = [
  {
    feature: "Payment Solutions ",
    item: "Multiple Payment Gateways: Support for various payment gateways like M-Pesa, Airtel Money, T-Kash, Visa, Mastercard, and PayPal, allowing traders and farmers to receive payments through their preferred channels. Mobile Wallet and  Cash on Delivery (COD), ",
  },
  {
    feature: "Credit Score and Lending Features ",
    item: "Credit Scoring System : [Transaction-Based Scoring, Behavioral Data Analysis,Alternative Data Sources]. Micro-Loans and Credit Lines : [ Embedded Micro-Loans, Revolving Credit Lines]. ",
  },
  {
    feature: "Financial Management Tools",
    item: "Automated Invoicing, Expense Tracking, Profit and Loss Statements, Tax Reporting, Savings Accounts, Budgeting Tools",
  },
  {
    feature: "Financial Literacy and Support",
    item: "Financial Literacy Training, Customer Support.",
  },
  {
    feature: " Transaction Security and Compliance",
    item: "Secure Payment Processing, Regulatory Compliance,  (KYC) requirements",
  },
 
];
