import React from "react";
import Styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { AttentionSeeker, Zoom } from "react-awesome-reveal";

function MainMenu() {
  return (
    <div className={Styles.Navbar}>
      <div className={Styles.rightSide}>
        <Link to="/">
          <img src="aboutus/logo.png" alt="ShamiriVeg" />
        </Link>
      </div>
      <div className={Styles.leftSide}>
        <AttentionSeeker>
          <Link to="/">
            <h1>Empowering Farmers and Small Scale Traders with Digital Apps </h1>
          </Link>
        </AttentionSeeker>
        <Zoom>
          <p>
          ShamiriVeg is an AgriTech startup empowering small scale traders and farmers with digital tools to successfully grow their businesses and improve their livelihoods.
          
          </p>
        </Zoom>
      </div>
    </div>
  );
}

export default MainMenu;
